<template>
    <v-container fluid class="mx-0 px-3">
        <DashboardView />
    </v-container>
</template>

<script>
export default {
    components: {
        DashboardView: () => import("@/components/user/DashboardView"),
    },
    name: "Dashboard",
    // Lifecycle hooks.
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
};
</script>
